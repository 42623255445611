<script setup>
</script>

<template>
  <div>
    <div class="bg-white dark:bg-gray-900">
      <div class="flex flex-1 h-screen">
        <div
          class="mx-auto py-12 my-auto flex flex-col w-full  items-center px-6 lg:w-[65%] flex-1"
        >
          <div class="text-center mb-20">
            <img
              src="~assets/logos/ap_logo_green.svg"
              alt="logo"
              class="w-20 mx-auto "
            >
          </div>

          <div class="w-full max-w-md">
            <slot />
          </div>

          <span
            class="w-full mt-8 text-gray-500 dark:text-gray-300 text-sm text-center"
          >
            ©Avantpage 2024 | Terms & Conditions | GDPR Privacy Policy
          </span>
        </div>
        <div
          class="hidden lg:block bg-primary-50 w-2/5"
        >
          <div class="flex flex-col items-center justify-center h-screen">
            <img
              src="~assets/undraw/u_world.svg"
              alt="cover"
              class="w-2/3 object-cover"
            >
            <img
              src="~assets/ap-slogan-gray-transparent.png"
              alt="cover"
              class="w-1/2 object-cover"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
